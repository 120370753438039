import React from 'react'

import { ReactComponent as AnalysisIcon } from '@assets/images/analysis.svg'
import { ReactComponent as PredictionIcon } from '@assets/images/prediction.svg'
import { ReactComponent as CommentsIcon } from '@assets/images/comments.svg'
import { ReactComponent as IncentivesIcon } from '@assets/images/incentives.svg'
import { ReactComponent as InsuranceIcon } from '@assets/images/insurance.svg'
import { ReactComponent as AutomotiveIcon } from '@assets/images/automotive.svg'
import { ReactComponent as PropertyIcon } from '@assets/images/property.svg'
import { ReactComponent as MarineIcon } from '@assets/images/marine.svg'
import { ReactComponent as HospitalityIcon } from '@assets/images/hospitality.svg'

export const sliderTop = [
  {
    text: <>Empowered with AI, ML and Blockchain and is at the core of all our products</>,
  },
  {
    text: <>with the potential to serve multiple industries.</>,
  },
]

export const sliderOne = [
  {
    title: `Analysis`,
    Img: AnalysisIcon,
    text: <> Analysing data on asset use and individual behaviour</>,
  },
  {
    title: `Prediction`,
    Img: PredictionIcon,
    text: <>Predicting errors and accidents in real-time</>,
  },
  {
    title: `Recommendation`,
    Img: CommentsIcon,
    text: <> Showing how to improve asset use and save money</>,
  },
  {
    title: `Incentives`,
    Img: IncentivesIcon,
    text: <>Providing additional rewards for safe and optimal asset use</>,
  },
]

export const sliderTwo = [
  {
    title: `Insurance`,
    Img: InsuranceIcon,
    text: <>Multiple lines, parametric based</>,
  },
  {
    title: `Automotive`,
    Img: AutomotiveIcon,
    text: <>Consumer and business</>,
  },
  {
    title: `Property`,
    Img: PropertyIcon,
    text: <>Consumer and business</>,
  },
  {
    title: `Marine`,
    Img: MarineIcon,
    text: <>Consumer and business</>,
  },
  {
    title: `Hospitality`,
    Img: HospitalityIcon,
    text: <>Consumer and business</>,
  },
]
