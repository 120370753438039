// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_cursor__zUQFT,\n.styles_aura__2vK8D {\n  display: block;\n  pointer-events: none;\n  position: fixed;\n  left: -35px;\n  top: -35px; }\n\n.styles_cursor__zUQFT {\n  width: 70px;\n  height: 70px;\n  background-color: #E7411B;\n  border-radius: 50px;\n  border: 1px solid rgba(255, 255, 255, 0.2);\n  overflow: hidden;\n  z-index: 54; }\n\n.styles_aura__2vK8D {\n  top: -15px;\n  left: -22px;\n  font-size: 18px;\n  z-index: 55;\n  color: #ffffff;\n  transition: transform 0.04s linear; }\n", "",{"version":3,"sources":["webpack://src/modules/Home/CollapseHold/Cursor/styles.module.scss","webpack://src/assets/styles/variables.scss"],"names":[],"mappings":"AAEA;;EAEE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,WAAW;EACX,UAAU,EAAA;;AAGZ;EACE,WAAW;EACX,YAAY;EACZ,yBCNgB;EDOhB,mBAAmB;EACnB,0CAA2B;EAC3B,gBAAgB;EAChB,WAAW,EAAA;;AAGb;EACE,UAAU;EACV,WAAW;EACX,eAAe;EACf,WAAW;EACX,cC1Be;ED2Bf,kCAAkC,EAAA","sourcesContent":["@import 'src/assets/styles/variables';\n\n.cursor,\n.aura {\n  display: block;\n  pointer-events: none;\n  position: fixed;\n  left: -35px;\n  top: -35px;\n}\n\n.cursor {\n  width: 70px;\n  height: 70px;\n  background-color: $orange01;\n  border-radius: 50px;\n  border: 1px solid rgba(#fff, 0.2);\n  overflow: hidden;\n  z-index: 54;\n}\n\n.aura {\n  top: -15px;\n  left: -22px;\n  font-size: 18px;\n  z-index: 55;\n  color: $white01;\n  transition: transform 0.04s linear;\n}\n","$white01: #ffffff;\n\n$gray01: #555656;\n$gray02: #55565666;\n$gray03: #6C6C6C;\n\n$red01: #E7411B;\n\n$orange01: #E7411B;\n\n$header: 2s;\n$durationWe: 1.4s;\n$delayBeforeWe: 0.1s;\n$durationCollapseInitialization: 0.3s;\n$delayAre: 1.4s;\n$delayHold: 1.6s;\n$delayIntroduce: 1.7s;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursor": "styles_cursor__zUQFT",
	"aura": "styles_aura__2vK8D"
};
export default ___CSS_LOADER_EXPORT___;
