import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import cx from 'classnames'

import styles from './styles.module.scss'

const NAVIGATION = [
  { name: 'Home', value: '/', pageName: 'home' },
  { name: 'News', value: '#', pageName: 'news' },
  { name: 'Careers', value: '#', pageName: 'careers' },
  { name: 'Contact Us', value: '/contact-us', pageName: 'contact-us' },
]

const link = [
  {
    name: 'Privacy Policy',
    value:
      'https://fleet-management-system.s3.eu-central-1.amazonaws.com/docs/ZamanIQ-privacy-policy.pdf',
  },
  { name: 'Cookies Policy', value: '' },
  { name: 'Accetable Use Policy', value: '' },
]

const Navigation = ({ themeBlack }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = useCallback(() => setIsOpen((prevIsOpen) => !prevIsOpen), [])

  const handleOpenBlank = (linkBlank) => {
    handleOpen()
    if (!linkBlank) {
      return
    }
    window.open(linkBlank, '_blank')
  }

  return (
    <>
      <div className={styles.navBar}>
        <div
          className={cx(styles.button, isOpen && styles.active, themeBlack && styles.themeBlack)}
          onClick={handleOpen}
        />
      </div>
      <div className={cx(styles.nav, isOpen && styles.activeNav)}>
        <div className={styles.navMenu}>
          {NAVIGATION.map((item) => (
            <Link onClick={handleOpen} className={styles.address} key={item.name} to={item.value}>
              {item.name}
            </Link>
          ))}
        </div>
        <div className={styles.linkMenu}>
          {link.map((item) => (
            <div
              onClick={() => handleOpenBlank(item.value)}
              className={cx(styles.address, styles.link)}
              key={item.name}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

Navigation.defaultProps = {
  themeBlack: false,
}

Navigation.propTypes = {
  themeBlack: PropTypes.bool,
}

export default Navigation
