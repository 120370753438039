import React, { useState } from 'react'

import Header from '@components/Header'
import CollapseItem from '@modules/Home/CollapseItem'
import CollapseAre from '@modules/Home/CollapseAre'
import CollapseHold from '@modules/Home/CollapseHold'
import CollapseIntroduce from '@modules/Home/CollapseIntroduce'

import styles from './styles.module.scss'

const collapseList = [
  {
    title: 'are',
    content: CollapseAre,
  },
  {
    title: 'hold',
    content: CollapseHold,
  },
  {
    title: 'introduce',
    content: CollapseIntroduce,
  },
]

const Home = () => {
  const [activeItem, setActiveItem] = useState(null)

  const handleSetActiveItem = (value) => {
    const validateValue = value === activeItem ? null : value

    setActiveItem(validateValue)
  }

  return (
    <>
      <Header />
      <div className={styles.home}>
        <div className={styles.inner}>
          <div className={styles.homeBlock}>
            <span className={styles.we}>We</span>
          </div>
          <div className={styles.collapse}>
            {collapseList.map((item, index) => (
              <CollapseItem
                key={index}
                onClick={() => handleSetActiveItem(index)}
                title={item.title}
                isActive={activeItem === index}
              >
                <item.content isActive={activeItem === index} />
              </CollapseItem>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

Home.defaultProps = {}

Home.propTypes = {}

export default Home
