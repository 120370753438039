import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { Navigation, Controller } from 'swiper'

import { ReactComponent as LogoTextIcon } from '@assets/images/logo-text.svg'
import { ReactComponent as LogoIcon } from '@assets/images/logo.svg'

import Cursor from './Cursor'
import { sliderTop, sliderOne, sliderTwo } from './date'

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'
import styles from './styles.module.scss'

const CollapseHold = ({ isActive }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null)

  const cursorRef = useRef()

  SwiperCore.use([Navigation])

  return (
    <>
      <div className={cx(styles.containerLogo, isActive && styles.isActive)}>
        <LogoIcon className={styles.iconSlider} />
        <div className={styles.blockLogo}>
          <LogoTextIcon className={styles.iconText} />
          <Swiper
            modules={[Controller]}
            onSwiper={setControlledSwiper}
            speed={1000}
            className={styles.swiperTwo}
            allowTouchMove={false}
          >
            {sliderTop.map(({ text }, index) => (
              <SwiperSlide key={`${text}-${index}`} className={styles.swiperText}>
                <span>{text}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div id="cursorWindow" className={cx(styles.container, isActive && styles.isActive)}>
        <div className={styles.cursor}>
          <Cursor ref={cursorRef} />
        </div>
        <Swiper
          navigation
          modules={[Controller]}
          controller={{ control: controlledSwiper }}
          speed={1500}
          slidesPerView={1}
          slidesPerGroup={1}
          onTouchMove={(swiper, { pageX, pageY }) => cursorRef.current.setPosition(pageX, pageY)}
          autoHeight={true}
        >
          <SwiperSlide>
            <div className={styles.wrapper}>
              {sliderOne.map(({ id, Img, title, text }, index) => (
                <div key={`${text}-${index}`} className={cx(styles.block, styles.blockOne)}>
                  {Img && (
                    <div>
                      <Img className={styles.icon} />
                    </div>
                  )}
                  <div className={styles.title}>{title}</div>
                  <div className={styles.text}>{text}</div>
                </div>
              ))}
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.sliderTwo}>
            <div className={styles.wrapper}>
              {sliderTwo.map(({ id, Img, title, text }, index) => (
                <div key={`${text}-${index}`} className={cx(styles.block, styles.blockTwo)}>
                  {Img && <Img className={styles.icon} />}
                  <div className={styles.title}>{title}</div>
                  <div className={styles.text}>{text}</div>
                </div>
              ))}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}

CollapseHold.defaultProps = {
  isActive: false,
}

CollapseHold.propTypes = {
  isActive: PropTypes.bool,
}

export default CollapseHold
