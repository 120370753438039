import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import styles from './styles.module.scss'

const CollapseAre = ({ isActive }) => {
  return (
    <div className={cx(styles.container, { [styles.containerActive]: isActive })}>
      <p>
        Zaman IQ is a R&D centre developing a variety of solutions and applications to resolve
        issues we see in industries today, with a central structure providing synergies as well as a
        diversified revenue stream.
      </p>
      <p>
        Our mission is to incentivise social responsibility and create social benefit, and we do
        this by incentivising consumers to create a safer society and healthier environment by
        giving them what they deserve.
      </p>
    </div>
  )
}

CollapseAre.defaultProps = {
  isActive: false,
}

CollapseAre.propTypes = {
  isActive: PropTypes.bool,
}

export default CollapseAre
