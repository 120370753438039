// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container__B9geQ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100vh;\n  width: 100vw; }\n\n.style_title__1mVyV {\n  font-size: 5vw; }\n\n.style_text__2yz1e {\n  font-size: 3.5vw; }\n", "",{"version":3,"sources":["webpack://src/modules/NotFound/style.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,YAAY,EAAA;;AAGd;EACE,cAAc,EAAA;;AAGhB;EACE,gBAAgB,EAAA","sourcesContent":["@import 'src/assets/styles/media-query';\n@import 'src/assets/styles/vars';\n@import 'src/assets/styles/variables';\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100vh;\n  width: 100vw;\n}\n\n.title {\n  font-size: 5vw;\n}\n\n.text {\n  font-size: 3.5vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__B9geQ",
	"title": "style_title__1mVyV",
	"text": "style_text__2yz1e"
};
export default ___CSS_LOADER_EXPORT___;
