import React from 'react'
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg'

import styles from './style.module.scss'

const NotFound = () => {
  setTimeout(function () {
    window.location.href = '/'
  }, 8 * 1000)

  return (
    <div className={styles.container}>
      <ErrorIcon />
      <div className={styles.title}>Page not found.</div>
      <div className={styles.text}>After 8 seconds, you will be redirected to the main page.</div>
    </div>
  )
}

export default NotFound
