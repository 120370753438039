// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/Axiforma-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/Axiforma-SemiBold.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../fonts/Axiforma-Bold.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "../fonts/Axiforma-Heavy.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n  font-weight: 400; }\n\n@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n  font-weight: 600; }\n\n@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n  font-weight: 700; }\n\n@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");\n  font-weight: 800; }\n", "",{"version":3,"sources":["webpack://src/assets/styles/fonts.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,4DAA2D;EAC3D,gBAAgB,EAAA;;AAGlB;EACE,kBAAkB;EAClB,uBAAuB;EACvB,4DAA4D;EAC5D,gBAAgB,EAAA;;AAGlB;EACE,kBAAkB;EAClB,uBAAuB;EACvB,4DAAwD;EACxD,gBAAgB,EAAA;;AAGlB;EACE,kBAAkB;EAClB,uBAAuB;EACvB,4DAAyD;EACzD,gBAAgB,EAAA","sourcesContent":["@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(\"../fonts/Axiforma-Regular.woff2\") format(\"woff2\");\n  font-weight: 400;\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(\"../fonts/Axiforma-SemiBold.woff2\") format(\"woff2\");\n  font-weight: 600;\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(\"../fonts/Axiforma-Bold.woff2\") format(\"woff2\");\n  font-weight: 700;\n}\n\n@font-face {\n  font-display: swap;\n  font-family: \"Axiforma\";\n  src: url(\"../fonts/Axiforma-Heavy.woff2\") format(\"woff2\");\n  font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
