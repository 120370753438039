import React from 'react'
import ReactDOM from 'react-dom'

import Root from '@modules/Root'

import '@assets/styles/fonts.scss'
import '@assets/styles/scroll.scss'
import '@assets/styles/media-query.scss'

ReactDOM.render(<Root />, document.getElementById('root'))
