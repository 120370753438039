import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import { ReactComponent as GeekbroLogo } from '@assets/images/geekbro.svg'
import { ReactComponent as KlasterLogo } from '@assets/images/klaster.svg'
import { ReactComponent as KaskoButikLogo } from '@assets/images/kaskobutik.svg'
import { ReactComponent as PartnershipLogo } from '@assets/images/partnership.svg'

import styles from './styles.module.scss'

const introduce = [
  {
    logo: <GeekbroLogo />,
    text: 'AI powered risk management tool providing asset performance and prediction data to make businesses more competitive',
    link: 'https://geekbro.ai/',
  },
  {
    logo: <KlasterLogo />,
    text: 'AI powered “super app” for car users, creating an ecosystem incentivising positive driver behaviour with rewards',
    link: false,
  },
  {
    logo: <KaskoButikLogo />,
    text: 'The parametrical insurance platform resembling a virtual boutique and covering 360° of personal risk.',
    partnership: <PartnershipLogo />,
    partnershipText: 'Partnership',
    link: 'https://kaskobutik.az/',
  },
]

const handleOpenLink = (link) => {
  if (!link) {
    return
  }
  window.open(link, '_blank')
}

const CollapseIntroduce = ({ isActive }) => {
  return (
    <div className={styles.container}>
      {introduce.map((item, index) => (
        <div key={index} className={cx(styles.column, isActive && styles.isActive)}>
          <div onClick={() => handleOpenLink(item.link)} className={styles.header}>
            {item.logo}
          </div>
          <div className={styles.content}>{item.text}</div>
          {item.partnership && (
            <div className={styles.block}>
              <div className={styles.partnership}>{item.partnership}</div>
              <div className={styles.partnershipText}>{item.partnershipText}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

CollapseIntroduce.defaultProps = {
  isActive: false,
}

CollapseIntroduce.propTypes = {
  isActive: PropTypes.bool,
}

export default CollapseIntroduce
