import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import throttle from 'lodash.throttle'
import styles from './styles.module.scss'

const Cursor = forwardRef((props, ref) => {
  const [[cursorX, cursorY], setCursorPosition] = useState([-50, -50])
  const [[auraX, auraY], setAuraPosition] = useState([-50, -50])

  const updateAuraPosition = useRef(throttle((pos) => setAuraPosition(pos), 10))

  const setPosition = useCallback((x, y) => {
    setCursorPosition([x, y])
    updateAuraPosition.current([x, y])
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      setPosition,
    }),
    [setPosition],
  )

  useEffect(() => {
    const onMouseMove = ({ pageX, pageY }) => setPosition(pageX, pageY)
    let cursorWindow = document.getElementById('cursorWindow')

    cursorWindow.addEventListener('mousemove', onMouseMove)

    return () => {
      cursorWindow.removeEventListener('mousemove', onMouseMove)
    }
  }, [setPosition])

  return (
    <>
      <div
        className={styles.cursor}
        style={{ transform: `translate(${cursorX}px, ${cursorY}px)` }}
      />
      <div className={styles.aura} style={{ transform: `translate(${auraX}px, ${auraY}px)` }}>
        drag
      </div>
    </>
  )
})

export default Cursor
