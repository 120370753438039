import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import styles from './styles.module.scss'

const CollapseItem = ({ onClick, title, children, isActive }) => {
  const iphone = (function () {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  })()

  return (
    <div className={cx(styles.collapseItem, isActive && styles.isActive, iphone && styles.iphone)}>
      <div className={styles.title} onClick={onClick}>
        {title}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

CollapseItem.defaultProps = {
  title: '',
  content: null,
  onClick: () => {},
  isActive: false,
}

CollapseItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

export default CollapseItem
