import React from 'react'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import Home from '@modules/Home'
import ContactUs from '@modules/ContactUs'
import NotFound from '@modules/NotFound'

import './styles.scss'

const routes = [
  { path: '/', exact: true, component: Home },
  { path: '/contact-us', exact: true, component: ContactUs },
  { path: '/not-found', exact: true, component: NotFound },
]

const Root = () => {
  return (
    <>
      <Router>
        <Switch>
          {routes.map((item) => (
            <Route key={item.path} {...item} />
          ))}
          <Redirect to="/not-found" />,
        </Switch>
      </Router>
    </>
  )
}

Root.defaultProps = {}

Root.propTypes = {}

export default Root
