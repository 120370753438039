import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Navigation from '@components/Navigation'

import { ReactComponent as HeaderLogo } from '@assets/images/logo-full.svg'
import { ReactComponent as LogoWhiteIcon } from '@assets/images/logo-white.svg'

import styles from './styles.module.scss'

const Header = ({ themeBlack }) => {
  return (
    <div className={cx(styles.header, themeBlack && styles.themeBlack)}>
      <Link className={styles.logo} to={'/'}>
        {themeBlack ? (
          <LogoWhiteIcon className={styles.icon} />
        ) : (
          <HeaderLogo className={styles.icon} />
        )}
      </Link>
      <Navigation themeBlack={themeBlack} />
    </div>
  )
}

Header.defaultProps = {
  themeBlack: false,
}

Header.propTypes = {
  themeBlack: PropTypes.bool,
}

export default Header
