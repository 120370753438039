import React from 'react'

import cx from 'classnames'
import Header from '@components/Header'

import styles from './styles.module.scss'

const date = {
  adress: 'Sixth Floor, 25 Farringdon Street, London, UK, EC4A 4AB',
  email: 'info@zamaniq.tech',
  phone: `+44 7540 697779`,
  map: 'https://www.google.com/maps/place/RSM/@51.5157844,-0.1041228,19z/data=!4m5!3m4!1s0x48761b52b8ddb95b:0xde195aa00a64dbc5!8m2!3d51.515674!4d-0.104316?hl=eng',
}

const ContactUs = () => {
  const iOS = (function () {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  })()

  const handleOpenMap = (link) => {
    if (!link) {
      return
    }
    window.open(link, '_blank')
  }

  return (
    <>
      <Header themeBlack />
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.logo}>Contact Us</div>
          <div className={styles.text} onClick={() => handleOpenMap(date.map)}>
            {date.adress}
          </div>
          <a className={styles.text} href={`mailto:${date.email}`}>
            {date.email}
          </a>
          <a className={styles.text} href={`tel:${date.phone}`}>
            {date.phone}
          </a>
        </div>
        <div className={cx(styles.textBottom, iOS && styles.textBottomIos)}>
          Zaman IQ Limited is incorporated in England and Wales (company number: 13781336).
        </div>
      </div>
    </>
  )
}

export default ContactUs
